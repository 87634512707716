import { useAuth0 } from "@auth0/auth0-react";
import { Download as DownloadIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import PlaceIcon from "@mui/icons-material/Place";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { Document, usePDF } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import reactElementToJSXString from "react-element-to-jsx-string";
import { Link, useLocation } from "react-router-dom";
import GoBack from "../../../components/GoBack/GoBack";
import dayjs, { SingleLineDate } from "../../../lib/dayjsConfig";
import CasePanel from "../../cases/CasePanel";
import ContactRiskSelect from "../../ContactRiskSelect/ContactRiskSelect";
import { convertMinutes } from "../../helpers/helpers";
import ICCReportPdf from "../../PdfTemplates/InfectionControlConsequencesPdf";
import MapReplayPdf from "../../PdfTemplates/MapReplayPdf";
import {
  containerStyle,
  downloadIconContainerStyle,
  downloadIconStyle,
  downloadLinkText,
  scrollableTableStyle,
} from "../../styles/styles";
import ContactFilters from "./ContactFilters";
import "./style.css";
import ViewMapReplayModal from "./TeamSafeMap/ViewMapReplay";

const consequencesTypeStyle = {
  padding: 0,
  mb: 1.1,
  color: "#333",
  fontWeight: 600,
  fontSize: 14,
  marginRight: "8px",
  marginTop: "6px",
  fontFamily: "Open Sans !important",
};

const leftButtonGroupStyle = {
  display: "inline-flex",
};

const downloadContainerStyle = {
  display: "inline-flex",
  alignItems: "center",
  position: "relative",
  marginTop: "6px",
};

export default function InfectionControlConsequences({
  user,
  pdfHeaderImage,
  currentLocation,
}) {
  const { state } = useLocation();
  const { getAccessTokenSilently } = useAuth0();

  const { infectionId, infectedPerson, analysisId } = state;
  //console.log(infectedPerson);

  const [caseData, setCaseData] = React.useState(infectedPerson);
  const [replayModalOpen, setReplayModalOpen] = React.useState(false);
  const [nextcontact, setNextcontact] = React.useState();
  const [previouscontact, setPreviouscontact] = React.useState();
  const [currentContact, setCurrentContact] = React.useState();
  const [pathogens, setPathogens] = React.useState();
  const [minDuration, setMinDuration] = React.useState(null);
  const [maxProximity, setMaxProximity] = React.useState(null);
  const [ICCReportInstance, updateICCPDF] = usePDF(<Document></Document>);
  const [accessToken, setAccessToken] = React.useState();
  const [currentEncounter, setCurrentEncounter] = React.useState(0);
  const [cookies, setCookie] = useCookies(["demoMode"]);

  const contacts = caseData?.contactPeople;
  const actionsString = caseData.actions
    ? JSON.parse(caseData.actions)
      .map((action) => action.value)
      .join(", ")
    : "";

  const encounterRisk =
    currentContact &&
      currentEncounter &&
      currentContact.encounters &&
      currentContact.encounters[currentEncounter].risk
      ? currentContact.encounters[currentEncounter].risk
      : "Open";

  const contactRisk =
    currentContact && currentContact.risk ? currentContact.risk : "Open";

  const [MapReplayPDFInstance, updateMapReplayPDF] = usePDF(
    <Document></Document>
  );
  const apiRef = useGridApiRef();
  const [visibleData, setVisibleData] = React.useState();

  const lastPdfTitle = React.useRef(null);

  const [currentContactPdfTable, setCurrentContactPdfTable] = React.useState();
  const timeoutRef = React.useRef(null);

  const RISK_LEVELS = ["Low", "Medium", "High", "Open"];

  const totalEncountersCount =
    contacts
      ?.map((contact) => contact.encounters.length)
      .reduce((a, b) => a + b, 0) ?? 0;
  const visibleEncountersCount =
    visibleData
      ?.map((contact) => contact.encounters.length)
      .reduce((a, b) => a + b, 0) ?? 0;

  const handleReplayModalOpen = () => setReplayModalOpen(true);

  const getTransmissionType = (pathogen) => {
    let transmissionType;
    pathogens.forEach((p) => {
      if (p.pathogen === pathogen) {
        if (p.isAirborne) {
          transmissionType = "Airborne";
        }
        if (p.isDroplet) {
          transmissionType = "Droplet";
        }
        if (p.isDirect) {
          transmissionType = "Direct";
        }
        if (p.isIndirect) {
          transmissionType = "Indirect";
        }
      }
      return "";
    });
    return transmissionType;
  };

  const fetchPathogens = async () => {
    try {
      const pathogenData = await fetch(
        `${process.env.REACT_APP_API_URL}/pathogens/policies`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const pathogenDataJson = await pathogenData.json();

      const pathogenArray = pathogenDataJson.records;
      pathogenArray.map((pathogen) => {
        if (pathogen.pathogen === caseData.pathogen) {
          setMinDuration(pathogen.durationBoundaryMinutes);
          setMaxProximity(pathogen.distanceBoundaryCm);
        }
      });
      setPathogens(pathogenArray);
    } catch (e) {
      console.log(e);
    }
  };

  const handleGoToNextcontact = () => {
    let nextIndex = contacts.findIndex(
      (contact) => contact.id === nextcontact.id
    );

    handleViewReplayOnMap(nextcontact, nextIndex);
  };

  const handleGoToPreviouscontact = () => {
    let previousIndex = contacts.findIndex(
      (contact) => contact.id === previouscontact.id
    );

    handleViewReplayOnMap(previouscontact, previousIndex);
  };

  const handleChangeEncounter = (e) => {
    setCurrentEncounter(e.target.value);
  };

  const handleReplayModalClose = () => {
    setCurrentContact(null);
    setCurrentContactPdfTable(null);
    setPreviouscontact(null);
    setNextcontact(null);
    setReplayModalOpen(false);
  };

  const handleViewReplayOnMap = async (contact, index) => {
    setCurrentContact(contact);
    setCurrentEncounter(0);

    handleReplayModalOpen();
  };

  const renderICCPdf = async () => {
    const reportIdResp = await fetch(
      `${process.env.REACT_APP_RTLS_URL}/report-id`
    );
    const reportIdJson = await reportIdResp.json();
    const reportId = reportIdJson.id;

    const pdf = ICCReportPdf(
      user.firstName + " " + user.lastName,
      user.email,
      pdfHeaderImage,
      caseData,
      contacts,
      actionsString,
      reportId
    );

    await fetch(`${process.env.REACT_APP_RTLS_URL}/reports/${reportId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        reportName: `Proxximos-ICC-${caseData.firstName}-${caseData.lastName
          }-${moment(new Date()).format("LLL")}.pdf`,
        reportContent: encodeURI(reactElementToJSXString(pdf)),
        userId: user.userId,
      }),
    });

    updateICCPDF(pdf);
  };

  React.useEffect(() => {
    if (ICCReportInstance && ICCReportInstance.blob) {
      saveAs(
        ICCReportInstance.blob,
        `Proxximos-ICC-${caseData.firstName}-${caseData.lastName
        }-${moment(new Date()).format("LLL")}.pdf`
      );
    }
  }, [ICCReportInstance]);

  const renderMapReplayPdf = async () => {
    const reportIdResp = await fetch(
      `${process.env.REACT_APP_RTLS_URL}/report-id`
    );
    const reportIdJson = await reportIdResp.json();
    const reportId = reportIdJson.id;

    const pdf = MapReplayPdf(
      user.firstName + " " + user.lastName,
      user.email,
      pdfHeaderImage,
      caseData,
      contacts,
      currentContact,
      actionsString,
      reportId
    );

    updateMapReplayPDF(pdf);

    await fetch(`${process.env.REACT_APP_RTLS_URL}/reports/${reportId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        reportName: `Proxximos-ICC-contact-${currentContact.infectedPersonSerial
          }-${currentContact.contactPersonSerial}-${moment(new Date()).format(
            "LLL"
          )}.pdf`,
        reportContent: encodeURI(reactElementToJSXString(pdf)),
        userId: user.userId,
      }),
    });
  };

  React.useEffect(() => {
    if (MapReplayPDFInstance && MapReplayPDFInstance.blob && currentContact) {
      const pdfTitle = `Proxximos-ICC-contact-${currentContact.infectedPersonSerial
        }-${currentContact.contactPersonSerial}-${moment(new Date()).format(
          "LLL"
        )}.pdf`;

      if (lastPdfTitle.current === null) {
        lastPdfTitle.current = setTimeout(() => {
          lastPdfTitle.current = null;
        }, 1000);
        saveAs(MapReplayPDFInstance.blob, pdfTitle);
      }
    }
  }, [MapReplayPDFInstance]);

  const fetchEncounters = async () => {
    try {
      console.log("Fetching encounters...");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cases/${infectionId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const caseDataJson = await response.json();
      //console.log("caseDataJson", caseDataJson);

      const contactsArray = caseDataJson.contactPeople;
      const formattedContacts = contactsArray.map((contact) => ({
        ...contact,
        id: contact.id ? contact.id : contact.encounters[0].contactTagSerial,

        fullName: contact.contactPerson.fullName
          ? contact.contactPerson.fullName
          : contact.encounters[0]?.contactTagSerial
            ? `Unknown Person (${contact.encounters[0].contactTagSerial})`
            : "Unknown Person",

        // set start time from earliest encounter
        start: contact.encounters.reduce((earliest, encounter) => {
          const encounterStartDate = new Date(encounter.start);
          return earliest < encounterStartDate ? earliest : encounterStartDate;
        }, new Date(contact.encounters[0].start)),

        // set proximity from closest encounter
        proximity: contact.encounters.reduce((smallest, encounter) => {
          return encounter.averageDistanceCm < smallest
            ? encounter.averageDistanceCm
            : smallest;
        }, Infinity),

      }));

      //console.log("formattedContacts", formattedContacts);

      setCaseData(caseDataJson);
      setIsLoadingEncounters(false);
      setVisibleData(formattedContacts);
      //console.log("formattedContacts", formattedContacts);

      if (currentContact) {
        // update currentContact if it's been changed
        const currentContactIndex = contactsArray.findIndex(
          (contact) => contact.id === currentContact.id
        );

        setCurrentContact(contactsArray[currentContactIndex]);
      }

      return caseDataJson;

    } catch (e) {
      console.log(e);
      if (timeoutRef.current === null) {
        clearTimeout(timeoutRef.current);
        setTimeout(() => {
          timeoutRef.current = fetchEncounters();
        }, 1000);
      }
    }
  };



  const [isLoadingEncounters, setIsLoadingEncounters] = React.useState(true);
  const [analysisProgress, setAnalysisProgress] = React.useState(false);

  const getAnalysisStatus = async () => {

    if (!analysisId) {
      console.log("No analysis ID found");
      setIsPolling(false);
      return;
    }

    console.log(`Fetching analysis for id ${analysisId}...`);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/cases/${infectionId}/analyses/${analysisId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const analysis = await response.json();
    return analysis;
  };

  const startAnalysisPolling = async () => {
    console.log("Starting analysis polling...");

    let pollCount = 1;
    let pollInterval = 3000;
    let currentAnalysisProgress = 0;
    const maxPolls = 50;
    const startedProgress = 0.05; // 5% - progress to start with before we recieve any analysis response
    const multiplier = 1; // Multiplier to increase the pollInterval

    setIsPolling(true);

    const poll = async () => {
      console.log(`Checking analysis progress... ${pollCount}`);

      const analysis = await getAnalysisStatus();
      if (!analysis) return;
      console.log("Analysis:", analysis);

      // use progress if available or fall back to poll count ** this is a temporary solution until we have a better way to track progress
      const newProgress = Math.round((analysis.progress || startedProgress) * 100); 
      const newBoundedProgress = Math.max(0, Math.min(100, newProgress)); // bound progress between 0 and 100
      setAnalysisProgress((prevProgress) => Math.max(prevProgress, newBoundedProgress)); // only update if progress is higher
  
      if (analysis.status == "Completed" || pollCount > maxPolls) {
        console.log("Analysis complete");

        // fetch encounters one last time
        await fetchEncounters();
        setIsPolling(false);

      } else {

        // if progress has increased, refresh encounters
        if (analysis.progress > currentAnalysisProgress) {
          fetchEncounters(); // Not awaiting as this can be done in the background
        }

        currentAnalysisProgress = analysis.progress;

        console.log("Analysis not complete, waiting...");
        setTimeout(poll, pollInterval);
        pollInterval *= multiplier; // Increase the pollInterval
      }

      pollCount++;

    };
    poll();
  };



  const [isPolling, setIsPolling] = useState(false);



  const SUMMARY_COLS = [
    {
      field: "id",
    },
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 145,
      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: false,
      valueGetter: (value, row) => value.row.contactPerson.fullName,
      renderCell: (params) => {
        return <div>{params.row.fullName}</div>;
      },
    },
    {
      field: "Role",
      headerName: "Role",
      align: "left",
      headerAlign: "left",
      editable: false,
      type: "string",
      valueGetter: (params) => {
        return params.row.contactPerson ? params.row.contactPerson.role : "";
      },
    },
    {
      field: "Encounters",
      headerName: "Encounters",
      align: "left",
      headerAlign: "left",
      minWidth: 100,
      type: "number",
      editable: false,
      valueGetter: (params) => {
        return params.row.encounters.length;
      },
    },
    {
      field: "start",
      headerName: "Start time",
      type: "string",
      align: "left",
      headerAlign: "left",
      minWidth: 145,
      editable: false,
      renderCell: (params) => {
        return <SingleLineDate date={params.row.start} />;
      },
    },
    {
      field: "DurationSeconds",
      headerName: "Duration",
      minWidth: 130,
      editable: false,
      type: "string",
      align: "left",
      headerAlign: "left",
      valueGetter: (params) => {
        let sum = 0;

        if (params.row.encounters.length === 0) {
          return `0 minutes`;
        }

        params.row.encounters.map((contact, key) => {
          sum += parseInt(contact.durationSeconds);
          return "";
        });
        return `${convertMinutes(parseInt(sum) / 60)}`;
      },
    },
    {
      field: "proximity",
      headerName: "Proximity",
      minWidth: 130,
      editable: false,
      align: "left",
      type: "number",
      headerAlign: "left",
      valueGetter: (params) => {
        return `${params.row.proximity} cm`;
      },
      sortComparator: (v1, v2) => {
        return parseInt(v1) - parseInt(v2);
      }
    },
    {
      field: "intensity",
      headerName: "Intensity",
      valueGetter: (params) => {
        return params.row.intensity ? Math.round(params.row.intensity) : 0;
      },
      editable: false,
      type: "number",
      align: "left",
      width: 125,
      headerAlign: "left",
      sortComparator: (v1, v2) => {
        return parseInt(v1) - parseInt(v2);
      },
    },
    {
      field: "risk",
      headerName: "Risk",
      type: "string",
      editable: false,
      align: "left",
      width: 105,
      headerAlign: "left",

      renderCell: (params) => {
        return (
          <>
            <ContactRiskSelect
              value={params.value ? params.value : "Open"}
              contact={params.row}
              handleRefresh={fetchEncounters}
            />
          </>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleViewReplayOnMap(params.row)}>
            <PlaceIcon />
          </IconButton>
        );
      },
    },
  ];

  const handleRowClick = (event) => {
    // TODO
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (!accessToken) {
        return;
      }

      await fetchEncounters(); // Fetch case data on component mount
      await startAnalysisPolling(); // Start polling analysis on component mount

      if (!pathogens) {
        fetchPathogens();
      }
    };

    fetchData();
  }, [accessToken]);

  React.useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "openid profile email",
          },
        });

        setAccessToken(accessToken);
      } catch (e) {
        console.log(e.message);
      }
    };

    getAccessToken();
  }, []);


  return (
    <div>
      {currentContact ? (
        <ViewMapReplayModal
          handleOpen={handleReplayModalOpen}
          handleClose={handleReplayModalClose}
          handleGoToNextcontact={handleGoToNextcontact}
          handleGoToPreviouscontact={handleGoToPreviouscontact}
          handleChangeEncounter={handleChangeEncounter}
          handleRefresh={fetchEncounters}
          encounterRisk={encounterRisk}
          contactRisk={contactRisk}
          currentLocation={currentLocation}
          displayDownloadReportButton={
            pdfHeaderImage &&
            caseData &&
            contacts &&
            currentContact &&
            actionsString
          }
          // PdfTemplate={MemoizedMapReplayPdf}
          renderPdf={renderMapReplayPdf}
          previouscontact={previouscontact}
          nextcontact={nextcontact}
          open={replayModalOpen}
          user={user}
          replay={true}
          infectedPerson={{
            ...caseData,
            transmissionType: getTransmissionType(caseData.pathogen),
            infectedUserId: caseData.personId,
          }}
          currentContact={currentContact}
          tags={[
            currentContact.serialAlphanumeric1,
            currentContact.serialAlphanumeric2,
          ]}
          minDate={currentContact.onsetTime}
          maxDate={currentContact.endTime}
          minDuration={minDuration}
          maxProximity={maxProximity}
          currentEncounter={currentEncounter}
          setCurrentEncounter={setCurrentEncounter}
          riskLevels={RISK_LEVELS}
        />
      ) : (
        <></>
      )}

      <Box style={containerStyle}>
        <GoBack />

        <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>
          Case Consequences
        </Typography>

        <br></br>
        <Grid container justifyContent="center" spacing={5}>
          <Grid item xs={2}>
            <h6
              className="table-title case-title"
              style={{ marginTop: 27, marginBottom: 12, display: "flex" }}
            >
              Case
              <Link
                to={"/infection-safe/manage-case"}
                state={{
                  user,
                  infection: {
                    ...caseData,
                    infectedPerson: caseData.infectedPerson
                      ? {
                        ...caseData.infectedPerson,
                        label: caseData.infectedPerson.fullName,
                      }
                      : null,
                  },
                }}
              >
                <EditIcon style={{ height: "18px", marginTop: "3px" }} />
              </Link>
            </h6>

            <CasePanel infectedPerson={caseData} />
          </Grid>

          <Grid item xs={10}>
            <>
              {!isLoadingEncounters ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <ContactFilters
                      contacts={contacts}
                      minDuration={minDuration}
                      maxProximity={maxProximity}
                      setVisibleData={setVisibleData}
                    />

                    {analysisProgress && (

                      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress variant="determinate" value={analysisProgress} />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography
                            variant="caption"
                            component="div"
                            sx={{ color: 'text.secondary' }}
                          >{`${Math.round(analysisProgress)}%`}</Typography>
                        </Box>
                      </Box>

                    )}

                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      gap: "20px",
                    }}
                  >
                    <div style={leftButtonGroupStyle}>
                      <Typography
                        id="type-menu-label"
                        sx={{
                          consequencesTypeStyle,
                          fontSize: 12,
                          fontWeight: "bold",
                          marginLeft: "30px",
                        }}
                      >
                        Encounters shown: {visibleEncountersCount} of{" "}
                        {totalEncountersCount} found
                      </Typography>
                      <br />
                      <br />
                    </div>


                    {/* Download report - currently hidden */}
                    <div style={cookies.demoMode ? { display: "none" } : { display: "none" }}>
                      <div>
                        <Button
                        >
                          <div
                            style={{
                              ...downloadContainerStyle,
                              marginTop: "-15px",
                            }}
                          >
                            <Typography sx={downloadLinkText}>
                              Download report
                            </Typography>
                            <div
                              className="icon-container"
                              style={downloadIconContainerStyle}
                            >
                              <DownloadIcon
                                className="download-icon"
                                style={downloadIconStyle}
                              />
                            </div>
                          </div>
                        </Button>
                      </div>
                    </div>

                  </div>

                  <Card sx={scrollableTableStyle}>
                    <DataGrid
                      apiRef={apiRef}
                      sx={{ backgroundColor: "#fff" }}
                      autoHeight
                      columnVisibilityModel={{
                        id: false,
                      }}
                      onRowClick={handleRowClick}
                      onRowDoubleClick={handleRowClick}
                      // rowHeight={"auto"}
                      getRowHeight={() => "auto"}
                      experimentalFeatures={{
                        ariaV7: true,
                        newEditingApi: true,
                      }}
                      columns={SUMMARY_COLS}
                      rows={visibleData}
                      slots={{
                        noRowsOverlay: () => (
                          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                            {isPolling ? (
                              <CircularProgress />
                            ) : (
                              <Typography
                                variant="h6"
                                component="div"
                                sx={{ fontWeight: "bold" }}
                              >
                                No encounters found
                              </Typography>
                            )}
                          </Box>
                        )
                      }}
                    />
                  </Card>
                </>
              ) : (

                // Still loading encounters
                <>
                  <Grid container>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "100px",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    </Grid>
                  </Grid>
                </>

              )}
            </>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
